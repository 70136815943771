.CardFeatures{
     margin-top: 8%;
     padding: 0px 10px 0px 50px;
     border-radius: 50px;
} 

.CardTitle{

     color: #000000;
     font-family: 'Montserrat',sans-serif;
     font-weight: 700;
     font-size: 20px;


}

.CardSubTitle{
     
     color: #000000;
     font-family: 'Raleway',sans-serif;
     font-weight: 600;
     font-size: 14px;


}

.Numbers{

     color: #ffd900;
     font-family: 'Montserrat',sans-serif;
     font-weight: 700;
     font-size: 30px;

}

.Title{
     color: #ffd900;
     font-family: 'Raleway',sans-serif;
     font-weight: 600;
     font-size: 22px;

}
.RowSection{
     
margin-top: 10%;

}

.DesignContainer{

     background-image: url("../../asset/image/summary.jpg");
     min-height: 300px;
     background-attachment: fixed;
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;



}


.DesignContainerOverly{

     position: absolute;
     min-height: 300px;
     width: 100%;
     background: rgba(27, 31, 92, 0.7);




}

.IconDesign{

     color: #ffffff;
     font-size: 35px;


}

.SmallIcon{
     color: #201d1d;
     font-size: 16;

}