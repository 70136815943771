.ServicesIcon{

   height: 150px;  
   width: 175px;


}

.serviceCard{

     box-shadow: 1px 1px 8px 0 gray;
     padding: 60px 30px  60px 30px;
     margin: 6px 6px 60px 6px;
     height: 30rem;
     /* serviceCard-shadow: insert 10px 10px 10px 0 green; */


}

.serviceCard:hover{

     box-shadow: 1px 2px 16px 0 rgb(224, 80, 80);
     padding: 60px 30px  60px 30px;
     margin: 6px 6px 6px 6px;
     height: rem;
     
     border-radius: 100%;

}

.serviceName{
     color: #051b35;
     margin-top: 1rem;
     font-family: 'Raleway',sans-serif;
     font-size: 24px;
     font-weight: 700;
}
.serviceDescription{
     color: #505050;
     margin-top: 0rem;
     font-family: 'Raleway',sans-serif;
     font-size: 16px;
     font-weight: 400;

}

.bottom{
     background: #0e8803 none repeat scroll 0 0;
     height: 5px;
     width: 125px;
     margin: -1.9rem auto 0;
     margin-bottom: 2rem;
}




.serviceMainTitle{
     color: #051b35;
     margin-top: 5rem;
     margin-bottom: 3rem;
     font-family: 'Montserrat',sans-serif;
     font-weight: 700;
     font-size: 28px;
}


.bottom1{
 
     margin: -3rem auto 0;
     margin-bottom: -10rem;
}

.bottom2{
 
     margin: -6rem auto 0;
     margin-bottom: -10rem;
}