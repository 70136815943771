.navTitle{
     font-family: 'Montserrat',sans-serif;
     color: #ffd900 !important;
     font-weight: 700;
     font-size: 30px;
}
.navTitleScroll{
     font-family: 'Montserrat',sans-serif;
     color: #50a808 !important;
     font-weight: 700;
     font-size: 30px;
}


.navBackground{
     -webkit-transition: background-color 400ms linear;
     -moz-transition: background-color 400ms linear;
     -o-transition: background-color 400ms linear;
     transition: background-color 400ms linear;
     background-color: rgba(228, 76, 76, 0)!important;
}
.navBackgroundScroll{
     -webkit-transition: background-color 400ms linear;
     -moz-transition: background-color 400ms linear;
     -o-transition: background-color 400ms linear;
     transition: background-color 400ms linear;
     background-color: #f8fbfc;
}


.navItem{
     text-decoration: none !important;
     font-family: 'Montserrat',sans-serif;
     color: #ffd900 ;
     font-weight: 700;
     font-size: 20px;
     text-align-last:right;
    
}
.navItemScroll{
     text-decoration: none !important;
     font-family: 'Montserrat',sans-serif;
     color: #0303fa !important;
     font-weight: 700;
     font-size: 20px;
     text-align-last:right;
}