.footerSection{
     margin-top: 8rem;
     box-shadow: 0 0 8px 0;
     background-color: #051b35;
}



.footerName{
     color: #ffffff;
     margin-top: 1rem;
     font-family: 'Raleway',sans-serif;
     font-size: 22px;
     font-weight: 600;
}



.social-container{
     margin-top: 30px;
}


a.social{
     margin: 0 1rem;
     transition: transform 250ms;
     display: inline-block;
}
a.social:hover{
     transform: translateY(-4px);
}

a.iconcolor{
     color: #ffffff;
}


.footerDescription{
     color: #e9e8e8;
     margin-top: 1rem;
     font-family: 'Raleway',sans-serif;
     font-size: 15px;
     font-weight: 400;

}

.footerLink{
     text-decoration: none;
     font-family: 'Raleway',sans-serif;
     font-size: 14px;
     font-weight: 600;
     color: #ffffff;

}
.footerLink:hover{
     text-decoration: none; 
     color: #ffd900;

}


.whatsapp{
     
      padding:16px;
       border-radius: 8px;
        background-color: #25D366;
         color: #fff;
          text-decoration: none;
           font-family: sans-serif;
            font-size: 16px;
            text-align-last:right;

}