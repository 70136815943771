.topFixedBanner{

     background-image: url("../image/banner.jpg");
     min-height: 550px;
     background-attachment: fixed;
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;

}

.topBannerOverly{

     position: absolute;
     min-height: 550px;
     width: 100%;
     background: rgba(17, 1, 48, 0.5);

}

.topContent{

     padding-top: 10em;
}


.topTitle{
     font-family: 'Montserrat',sans-serif;
     font-weight: 700;
     color: #ffffff;
     font-size: 48px;
}
.topSubTitle{
     font-family: 'Raleway',sans-serif;
     font-weight: 600;
     color: #ffffff;
     font-size: 22px;

} 

